export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "es": {
        "PLACE_HOLDERS": {
          "CLIENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Busca un cliente"])}
        },
        "LABELS": {
          "ADVANCE_SERACH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Busqueda de ventas"])},
          "RANGE_DATES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rango de fechas"])},
          "FOLIO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folio venta"])},
          "CLIENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliente"])},
          "PORCENTAGE_SALE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porcentaje de venta"])},
          "AMOUNT_SELECTED": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Monto total: ", _interpolate(_named("amount"))])}
        },
        "BUTTONS": {
          "SEARCH_SALES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar ventas"])},
          "BILL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facturar"])}
        },
        "MESSAGES": {
          "NO_DATA_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontraron ventas que coincidan"])}
        }
      }
    }
  })
}
