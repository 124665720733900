
import { computed, defineComponent, toRefs, ref, watch, onMounted } from "vue";
import FieldsBill from "./FieldsBill.vue";
//import InputNumber from "@/components/forms/InputNumber.vue" 
import * as Validations from "yup";
import { Form } from "vee-validate";
import BillFiield from "@/components/forms/BillFiield.vue";
import NewBill from "@/models/bill/NewBill";
import {removeCurrencyFormat} from "@/core/shared/functions/Formatter"
import NewBillModule from "@/store/modules/bill/modules/new-bill";
import { getModule } from "vuex-module-decorators";
import TableSalesToBillModule from "@/store/modules/bill/modules/table-sales-to-bill";
import BillAmount from "@/models/bill/BillAmount";
import emitter from "@/core/emitters";
import { hideModalById } from "@/core/helpers/dom";

export default defineComponent({
    components: {
        FieldsBill
        , BillFiield
        , Form
    },
    props: {
        isMultipleBill: {
            type: Boolean
            , default: false
        },
        amountToBill: {
            type: Number
            , required: true
            , default: 0
        },
    },
    setup(props){
        const module = getModule(NewBillModule);
        const moduleTable = getModule(TableSalesToBillModule);
        const { amountToBill } = toRefs(props);
        const validationSchema = computed(() => Validations.object().shape({
                name: Validations.string().default('').required()
                , businessName: Validations.string().default('').required()
                , rfc: Validations.string().default('').required()
                , useCfdiId: Validations.string().default('')
                , zipCode: Validations.string().default('').required()
                , email: Validations.string().email().required()
                , amountBill: Validations.number().required().equals([amountToBill.value], "El valor debe ser igual al monto de factura")
            }));
        const bills = ref([] as BillAmount[]);
        const form = ref();
        const updateBills = (newBills) => {
            bills.value = newBills;
        }

        const saveBill = (values, {resetForm}) =>{
            //guarda la factura
            const bill = {
                ...values,
                bills: bills.value.filter(b => removeCurrencyFormat(b.amount + "") > 0)
                    .map(b => {
                        return {...b, amount: removeCurrencyFormat(b.amount + "")}
                    })
            } as NewBill;
            console.log(bill);
            //llamar al metodo de vuex y reset form
            module.SAVE_BILL(bill).then((isCorrect) => {
                if(isCorrect){
                    resetForm();
                }
            });
        }
        watch(moduleTable.bills, (newBills) => {
            bills.value = newBills;
        })
        
        onMounted(() => {
            emitter.on('resetBillForm', () => {
                bills.value = [{
                    amount: 0
                    , positionNumber: 1
                    , saleId: ''
                    , saleFolio: ''
                }]
                form.value.resetForm();
                hideModalById('#new-bill-form');//TODO posiblemente se quite
            })
        })
        return {
            saveBill
            , updateBills
            , validationSchema
            , form
        }
    }
})
