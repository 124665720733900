
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { translate } from "@/core/plugins/i18n";
import { defineComponent, onMounted, computed, reactive, ref } from "vue";
import { Form } from "vee-validate";
import SelectFilterRemote from "@/components/forms/SelectFilterRemote.vue"
import InputText from "@/components/forms/InputText.vue"
import DatePickerRange from "@/components/forms/DatePickerRange.vue"
import { debounce } from "lodash";
import ModuleMaster from "@/components/c-module-master/module-master.vue";
import Datatable from "@/components/c-datatable/Datatable.vue";
import Datarow from "@/components/c-datatable/Datarow.vue";
import { formatToDatatable } from "@/common/formaterToDatatable";
import NoDataMessage from "@/components/shared/NoDataMessage.vue";
import ModalNewBill from './components/ModalNewBill.vue';
import TableSalesToBillModule from "@/store/modules/bill/modules/table-sales-to-bill";
import { getModule } from "vuex-module-decorators";
import FiltersSalesToBill from "@/models/bill/filters/FiltersSalesToBill";
import { InputTextMaskTypes } from '@/core/enums/input-text-mask-types';
import {formatNumeric} from "@/core/shared/functions/Formatter"

export default defineComponent({
    components: {
        Form
        , SelectFilterRemote
        , DatePickerRange
        , InputText
        , ModuleMaster
        , Datatable
        , Datarow
        , NoDataMessage
        , ModalNewBill
    },
    setup(){
        const firstSearch = ref(false);
        const moduleTable = getModule(TableSalesToBillModule);
         /**
         * CONFIGURACIONES DE LA TABLA
         */
        const configTable = reactive([
            { PropName: "folio", HeadLabel: "Folio", Type: "text", VisibleInGrid: true },
            { PropName: "clientName", HeadLabel: "Cliente", Type: "text", VisibleInGrid: true },
            { PropName: "date", HeadLabel: "Fecha", Type: "text", VisibleInGrid: true },
            { PropName: "amount", HeadLabel: "Importe", Type: "moneda", VisibleInGrid: true },
            { PropName: "productNames", HeadLabel: "Productos", Type: "text", VisibleInGrid: true },        
            { PropName: "id", HeadLabel: "Id", Type: "text", VisibleInGrid: false },        
        ]);
        const headers = computed(() => {
            return configTable.filter(ct => ct.VisibleInGrid).map(h => h.HeadLabel);
        });
        /*
            Methods
         */
       
        const searchSales = (filters: FiltersSalesToBill) => {
            //busca las ventas por medio de los filtros
            moduleTable.UPDATE_FILTERS(filters as FiltersSalesToBill).finally(() => firstSearch.value = true);
        }

        const searchClient = debounce((filterName: string) => {
             //call store
            moduleTable.SEARCH_CLIENTS(filterName);
        }, 650, { trailing: true});

        const isSelectedSale = (id: string) => {
            return moduleTable.isSelected(id);
        }

        const addListSelected = (event, id: string) => {
            const isAdded = event.target.checked;
            const payload = { id: id, isAdded: isAdded }
            moduleTable.CHANGE_SELECTED_SALE_IDS(payload)
        }

        const changeSelectionAllItems = (isCheck: boolean) =>{
            //cambio los valores de todas las secciones
            if(isCheck){
                //add all elements
                moduleTable.selectAllSalesRows();
            } else {
                moduleTable.resetSaleIdsSelected();
            }
        } 

        
        const handleChangePage = (page: number) => {
            moduleTable.CHANGE_PAGE({ currentPage: page });
        }

        /**
         * From Store
         */
        const allAreSelected = computed(() => saleIdsSelected.value.length == renderRows.value.length)
        const clients = computed(() => moduleTable.getClientOptions);
        const totalRecords = computed(() => moduleTable.totalRecords);
        const sizePage = computed(() =>moduleTable.pagination.pageSize);
        const renderRows = computed(() => formatToDatatable(moduleTable.records, configTable));
        const isMultipleBill = computed(() => saleIdsSelected.value.length > 1)
        const amountToBill = computed(() => {
            const amounts = renderRows.value.map(s => +s.Cells[3].Value);
            const amountTotal = amounts.reduce((accumulator, current) => accumulator + current, 0);
            return parseFloat(amountTotal.toFixed(2));
        })
        const loadingSelectClient = computed(() => moduleTable.loadingSelectClient)
        const saleIdsSelected = computed(() => moduleTable.saleIdsSelected)
        onMounted(() => {
            setCurrentPageBreadcrumbs(translate('BREADCRUMB.BILL'), [translate('BREADCRUMB.BILLING')]);
        });


        return {
            searchSales
            , addListSelected
            , changeSelectionAllItems
            , isSelectedSale
            , allAreSelected
            , handleChangePage
            , searchClient
            , clients
            , totalRecords
            , sizePage
            , headers
            , renderRows
            , saleIdsSelected
            , isMultipleBill
            , amountToBill
            , firstSearch
            , loadingSelectClient
            , maskPercentage: InputTextMaskTypes.Percentage
            , formatNumeric
        }
    }
})
