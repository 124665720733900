
import { defineComponent } from "vue";
import DynamicContentModal from "@/components/modals/general/DynamicContentModal.vue"
import BillSimpleForm from '@/views/billing/bill/components/BillSimpleForm.vue';

import {formatNumeric} from "@/core/shared/functions/Formatter"
export default defineComponent({
    components:{
        DynamicContentModal
        , BillSimpleForm
    },
    props:{
        isMultipleBill: {
            type: Boolean
            , default: false
        },
        amountToBill: {
            type: Number
            , required: true
            , default: 0
        },
    },
    setup() {
        //
        const cleanForm = () => {
            //emitter.emit("resetBillForm"); se llama cuando s eguarda el reguistro
        }
        return {
            cleanForm
            , formatNumeric
        }
    },
})
