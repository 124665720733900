export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "es": {
        "TITLES": {
          "BILL_MULTIPLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Facturación Multiple"])},
          "BILL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facturación"])}
        },
        "SUB_TITLE_BILL_MULTIPLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solo se puede hacer una factura por venta"])},
        "SUB_TITLE_BILL": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Puedes dividir el monto de ", _interpolate(_named("amount")), " en varias facturas"])}
      }
    }
  })
}
