
import { defineComponent, toRefs, computed, ref, watch, onMounted } from "vue";
import { useField } from "vee-validate";
import BillAmount from "@/models/bill/BillAmount";
import {VMoney} from 'v-money'
import {formatNumeric} from "@/core/shared/functions/Formatter"
import {removeCurrencyFormat} from "@/core/shared/functions/Formatter"
import TableSalesToBillModule from "@/store/modules/bill/modules/table-sales-to-bill";
import { getModule } from "vuex-module-decorators";
import emitter from "@/core/emitters";

export default defineComponent({

    props:{
        name: {
            type: String
            , required: true
        }, 
        amountToCover: {//cantidad a cubrir de la factura
            type: Number
            , required: true
        }, 
        disabled: {
            type: Boolean
            , default: false
        },
        isMultipleBill: {//si es multiple factura se puede dividir la factura
            type: Boolean
            , default: false
        }
    },
    emits:['change-bills'],
    setup(props, {emit}){
        const { amountToCover } = toRefs(props);
        const { errorMessage, handleChange, resetField } = useField(props.name);
        const moduleTable = getModule(TableSalesToBillModule);
        const bills = ref([] as BillAmount[])

        const changeAmountCovered = () => handleChange(amountCovered.value);
        
        const changeNumBills = (action: string) =>{            
            if(action == '+'){
                bills.value.push({
                    ...bills.value[0]
                    , positionNumber: bills.value.length + 1
                    , amount: 0
                });
            } else {
                if(bills.value.length == 1 ) return;
                bills.value.pop();
            }
        }
        const getPorcentage = (amount) => {
            return `${(( removeCurrencyFormat(amount + "") / amountToCover.value) * 100).toFixed(2)}%`
        }
        //cantidad faltante por cubrir
        const missingAmount = computed(() =>  (amountToCover.value - amountCovered.value).toFixed(2));
        const amountCovered = computed(() => +(bills.value
            .map(bill => removeCurrencyFormat(bill.amount + ""))
            .reduce((accumulator, current) => accumulator + current, 0).toFixed(2)));
        const money = ref(({//en algun momento se usara multilenguaje y aqui pueden aplicar los cambios
            decimal: '.',
            thousands: ',',
            prefix: '$ ',
            suffix: '',
            precision: 2,
            masked: false
        }))
        const billStore = computed(() =>  moduleTable.bills);
        watch(bills, (newValue) => {
            emit('change-bills', newValue)
        })
        watch(billStore, (newValue) => {
            //se multiplica para obtener el verdadero valor en el input de moneda
            bills.value = newValue.map(b => ({...b, amount: b.amount*100}));
            changeAmountCovered();
        })
        onMounted(() => {
            /*emitter.on('resetBillForm', () => {
                bills.value = [{
                    amount: 0
                    , positionNumber: 1
                    , saleId: ''
                    , saleFolio: ''
                }]
            })
            emit('change-bills', bills.value);
            changeAmountCovered();*/
        })
        return {
            errorMessage
            , missingAmount
            , amountCovered
            , money
            , bills
            , changeAmountCovered
            , changeNumBills
            , getPorcentage
            , formatNumeric
        }
    }
    , directives:{
        money: VMoney
    }
})
